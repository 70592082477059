import { Routes } from '@angular/router';

export const routes: Routes = [
  {
    path: '',
    // pathMatch: 'full',
    children: [
      {
        path: 'authentication',
        loadChildren: () =>
          import('./pages/authentication/authentication.module').then(
            (m) => m.AuthenticationModule
          ),
      },
    ],
  },
  {
    path: 'citizen/delete-account',
    redirectTo: 'authentication/delete-citizen',
  },
];
