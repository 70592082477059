import { Injectable, input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import {
  DeleteCitizen,
  IDefaultResponse,
  IOfficial,
  OtpRequest,
  Tokens,
} from '../interfaces/defaults';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private apiUrl = environment.apiBaseUrl + 'api/v1/officials';
  private ination = 'ination_';
  private officialDetails: IOfficial | undefined;

  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  constructor(private http: HttpClient) {}

  login(username: string, password: string): Observable<any> {
    return this.http
      .post<any>(`${this.apiUrl}/login`, { username, password })
      .pipe(
        map((tokens) => {
          this.storeTokens(tokens);
          return tokens;
        })
      );
  }

  refreshToken(): Observable<Tokens> {
    if (!this.getRefreshToken()) {
      return new Observable<Tokens>((observer) =>
        observer.error('No refresh token')
      );
    }

    return this.http
      .post<Tokens>(`${this.apiUrl}/refresh-token`, {
        refreshToken: this.getRefreshToken(),
      })
      .pipe(
        tap((tokens) => {
          this.storeTokens(tokens);
          this.refreshTokenSubject.next(tokens.accessToken);
          return tokens;
        })
      );
  }

  hasAccessToken(): boolean {
    const accessToken = localStorage.getItem(this.ination + 'accessToken');
    return !!accessToken;
  }

  hasRefreshToken(): boolean {
    const refreshToken = localStorage.getItem(this.ination + 'refreshToken');
    return !!refreshToken;
  }

  getRefreshToken(): string | null {
    return localStorage.getItem(this.ination + 'refreshToken');
  }

  getDetails(): Observable<IOfficial> {
    return this.http.get<IOfficial>(`${this.apiUrl}/me`).pipe(
      tap((details) => (this.officialDetails = details)) // Store the response in the variable
    );
  }

  get currentOfficialDetails(): IOfficial | undefined {
    return this.officialDetails;
  }

  storeTokens(tokens: Tokens): void {
    localStorage.setItem(this.ination + 'accessToken', tokens.accessToken);
    localStorage.setItem(this.ination + 'refreshToken', tokens.refreshToken);
  }

  logout() {
    // TODO: Implement the logout method at server side
    localStorage.removeItem(this.ination + 'accessToken');
    localStorage.removeItem(this.ination + 'refreshToken');
  }

  validateOfficialOtp(otpRequest: OtpRequest): Observable<Tokens> {
    return this.http.post<Tokens>(`${this.apiUrl}/validate`, otpRequest).pipe(
      map((tokens) => {
        this.storeTokens(tokens);
        this.refreshTokenSubject.next(tokens.accessToken);
        return tokens;
      })
    );
  }

  validateOfficialDeleteOtp(
    otpRequest: OtpRequest
  ): Observable<IDefaultResponse> {
    return this.http.post<IDefaultResponse>(
      `${this.apiUrl}/delete-official`,
      otpRequest
    );
  }

  validateCitizenDeleteOtp(
    otpRequest: DeleteCitizen
  ): Observable<IDefaultResponse> {
    return this.http.post<IDefaultResponse>(
      `${this.apiUrl}/delete-citizen`,
      otpRequest
    );
  }

  getAccessToken(): string | null {
    return localStorage.getItem(this.ination + 'accessToken');
  }

  // getAccessToken(): Observable<string> {
  //   return new BehaviorSubject<string>(
  //     localStorage.getItem(this.ination + 'accessToken') || ''
  //   ).asObservable();
  // }
}
